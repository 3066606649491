body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-color: black;
  --text-alternative-color: white;
  --page-background: #ede1ff;
  --page-container-background: #d2c2ff;
  --component-color: #48445b;
  --activities-color: #bfc7ff;
  --activity-color: #3b2773;
  --dark-mode-button-background: #a578dc;
  --white: white;
  --black: black;
  --maroon: #a02828;
  --dark-yellow: #ecec58;
  --blue: #0e0e5d;
  --light-blue: #7e7ecf;
  --gray: #484848;
}

body.darkMode {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-color: white;
  --text-alternative-color: black;
  --page-background: #15153b;
  --page-container-background: #31316d;
  --component-color: white;
  --activities-color: #a498e2;
  --activity-color: #443591;
  --dark-mode-button-background: #502882;
  --white: white;
  --black: black;
  --maroon: #a02828;
  --dark-yellow: #ecec58;
  --blue: #0e0e5d;
  --light-blue: #7e7ecf;
  --gray: #484848;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}