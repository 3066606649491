.homeBackground {
    height: 100vh;
    width: 100%;
    background-color: var(--page-background);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profileContainer {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    border-radius: 1rem;
    align-items: center;
    gap: 0.5rem;
    padding-right: 1rem;
    margin-top: 2vh;
    margin-left: 2vw;
    transition: all 0.1s ease-in-out;
    box-shadow: #3c40434d 0rem 0.063rem 0.125rem 0rem, #3c404326 0rem 0.063rem 0.188rem 0.063rem;
    background-color: var(--activities-color);
}

.profileContainer:active {
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.profileContainer img {
    height: 6vh;
    border-radius: 1rem;
}

.profileContainer span {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: large;
}

.profileContainer div {
    display: flex;
    align-items: center;
}

.homeActivityContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% auto;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 60%;
    border-radius: 1rem;
    background-color: var(--page-container-background);
    box-shadow: #3c40434d 0rem 0.063rem 0.125rem 0rem, #3c404326 0rem 0.063rem 0.188rem 0rem;
}

.topContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addWordContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.addWordLayout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-inline: 10%;
    gap: 0.5rem;
}

.addWord {
    background-color: var(--white);
    width: 80%;
    font-size: large;
    padding-inline: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 1.25rem;
    border: none;
    box-shadow: #3c40434d 0rem 0.063rem 0.125rem 0rem, #3c404326 0rem 0.063rem 0.188rem 0.063rem;
    transition: all 0.2s ease-in-out;
}

.addWord:focus {
    outline: none;
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.addWordButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--activity-color);
    color: var(--white);
    border-radius: 1rem;
    padding-inline: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: none;
    transition: all 0.1s ease-in-out;
    box-shadow: #00000040 0rem 0.0625em 0.0625em, #00000040 0rem 0.125em 0.5em, #ffffff1a 0rem 0rem 0rem 0.063rem inset;
}

.addWordButton:active {
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.topHr {
    width: 95%;
    height: 0.1rem;
    margin-top: 1.25rem;
}

.bottomContainer {
    height: 100%;
    display: flex;
    justify-content: center;
}

.activityListContainer {
    overflow: auto;
    height: 72vh;
    width: 85%;
    background-color: var(--activities-color);
    border-radius: 0.75rem;
    box-shadow: #00000040 0rem 0.0625em 0.0625em, #00000040 0rem 0.125em 0.5em, #ffffff1a 0rem 0rem 0rem 0.063rem inset;
}

.activityListContainer::-webkit-scrollbar {
    width: 0.5rem;
}

.activityListContainer::-webkit-scrollbar-thumb {
    background-color: var(--activity-color);
    border-radius: 1rem;
}

.singleElement {
    background-color: var(--activity-color);
    margin-top: 0.5rem;
    margin-inline: 0.5rem;
    padding-inline: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 1.5rem;
    color: var(--white);
    display: flex;
    justify-content: space-between;
}

.activityName {
    font-size: 1rem;
}

.hideLayout {
    display: none;
}

.activityNameEdit {
    display: flex;
    flex-direction: column;
}

.activityNameEdit textarea {
    border-radius: 0.5rem;
    width: 30vw;
    height: 10vh;
    resize: none;
}

.editButtonsLayout {
    display: flex;
    margin-top: 0.5rem;
    gap: 0.5rem;
}

.editButton {
    cursor: pointer;
    font-size: medium;
    font-weight: 500;
    border-radius: 0.5rem;
    padding-inline: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: none;
    color: var(--black);
    background-color: var(--dark-yellow);
    box-shadow: #00000040 0rem 0.0625em 0.0625em, #00000040 0rem 0.125em 0.5em, #ffffff1a 0rem 0rem 0rem 0.063rem inset;
}

.editButton:active {
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.editCancelButton {
    cursor: pointer;
    font-size: medium;
    font-weight: 500;
    border-radius: 0.5rem;
    padding-inline: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: none;
    color: var(--white);
    background-color: var(--gray);
    box-shadow: #00000040 0rem 0.0625em 0.0625em, #00000040 0rem 0.125em 0.5em, #ffffff1a 0rem 0rem 0rem 0.063rem inset;
}

.editCancelButton:active {
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.activityNameEdit textarea::-webkit-scrollbar {
    width: 0.5rem;
}

.activityNameEdit textarea::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 0.5rem;
}

.activityNameEdit textarea::-webkit-scrollbar-track {
    background-color: var(--light-blue);
    border-radius: 0.5rem;
}

.activityDateTime {
    font-size: 0.8rem;
}

.rightElements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.modifyIcons {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 5rem;
    height: 2rem;
    border-radius: 0.5rem;

}

.editMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--black);
    background-color: var(--dark-yellow);
    border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.deleteMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--white);
    background-color: var(--maroon);
    border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.noDataFound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: large;
    font-weight: 600;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .addWord {
        width: 60%;
    }
}

@media screen and (max-width: 750px) {
    .homeActivityContainer {
        width: 90%;
        height: 85vh;
        margin-top: 2rem;
    }

    .activityListContainer {
        height: 70vh;
    }

    .activityName {
        font-size: 0.7rem;
    }

    .activityDateTime {
        font-size: 0.6rem;
    }
}