.loginBackground {
    height: 100vh;
    background-color: var(--page-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginContainer {
    display: grid;
    grid-template-rows: 40% 20% auto;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 30%;
    border-radius: 1rem;
    background-color: var(--page-container-background);
    box-shadow: #3c40434d 0rem 0.063rem 0.125rem 0rem, #3c404326 0rem 0.063rem 0.188rem 0rem;
}

@media screen and (max-width: 750px) {
    .loginContainer {
        width: 90%;
    }
}

.appLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}

.header {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 500;
}

.googleCard {
    cursor: pointer;
    height: 6vh;
    display: flex;
    align-items: center;
    padding-inline: 1.5rem;
    border-radius: 2rem;
    color: var(--text-alternative-color);
    background-color: var(--component-color);
    box-shadow: #ffffff33 0rem 0rem 0rem 0.063rem inset, #000000e6 0rem 0rem 0rem 0.063rem;
    transition: all 0.1s ease-in-out;
}

.googleCard:active {
    box-shadow: #06182c66 0rem 0rem 0rem 0.125rem, #06182ca6 0rem 0.25rem 0.375rem -0.063rem, #ffffff14 0rem 0.063rem 0rem inset;
}

.googleCard span {
    font-weight: 500;
    margin: auto;
}